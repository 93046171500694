<template>
  <div class="md:max-w-3/4 ml-auto mr-auto p-6 bg-white rounded-lg mb-4">
    <h1 class="font-bold text-lg text-center text-darkblue mb-3">
      {{ $route.params.mode == "new" ? "New" : "Manage" }} Seller
    </h1>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(SubmitSeller)" class="">
        <div class=" mb-3 md:mb-6 justify-around flex flex-col md:flex-row">
          <div class="w-full md:w-5/12 mb-2">
            <label class="block md:text-xs" for="Seller's name"> Name </label>
            <validation-provider
              rules="required|alpha_spaces"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="seller.name"
                name="Seller's name"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="w-full md:w-5/12 mb-2">
            <label class="block md:text-xs" for="Seller's email">
              Email
            </label>
            <validation-provider rules="email" v-slot="{ errors }">
              <input
                v-model="seller.email"
                type="email"
                name="Seller's email"
                class="border-2 md:text-xs w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class=" mb-3 md:mb-6 justify-around flex flex-col md:flex-row">
          <div class="w-full md:w-5/12 mb-2">
            <label class="block md:text-xs" for="Phone number">
              Phone Number
            </label>
            <validation-provider rules="required|numeric" v-slot="{ errors }">
              <input
                name="Phone number"
                v-model="seller.phoneNumber"
                type="text"
                class="border-2 md:text-xs w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="w-full md:w-5/12 mb-2">
            <label class="block md:text-xs" for="Seller's address">
              Address
            </label>
            <validation-provider rules="custom_text" v-slot="{ errors }">
              <input
                v-model="seller.address"
                type="text"
                name="Seller's address"
                class="border-2 md:text-xs w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <button
          type="submit"
          class="text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full"
        >
          <svg
            v-if="loadingSpinner"
            class="inline animate-spin mr-2"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
          {{ $route.params.mode == "new" ? "Add Seller" : "Save Seller" }}
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations } from "vuex";

  export default {
    name: "ManageSeller",
    props: {},
    components: { ValidationProvider, ValidationObserver },
    apollo: {
      seller: {
        query: gql`
          query seller($id: ID!) {
            seller(id: $id) {
              name
              phoneNumber
              address
              email
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        skip() {
          return this.$route.params.mode != "edit";
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.seller = true;
            return;
          }

          this.loading.seller = false;
        },
      },
    },
    data() {
      return {
        loading: {
          seller: false,
        },
        loadingSpinner: false,
        seller: {
          name: "",
          phoneNumber: "",
          address: "",
          email: "",
        },
      };
    },
    computed: {},
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      SubmitSeller() {
        if (this.$route.params.mode != "edit") {
          this.CreateSeller();
          return;
        }
        this.UpdateSeller();
      },
      async CreateSeller() {
        this.loadingSpinner = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($seller: SellerInput!) {
                createSeller(seller: $seller) {
                  _id
                }
              }
            `,
            // Parameters
            variables: {
              seller: {
                name: this.seller.name,
                phoneNumber: this.seller.phoneNumber,
                ...(this.seller.email && {
                  email: this.seller.email,
                }),
                ...(this.seller.address && {
                  address: this.seller.address,
                }),
              },
            },
          });

          this.loadingSpinner = false;
          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }
          this.$emit("show-alert", {
            type: "success",
            message: "Seller created successfully",
          });

          if (this.$route.query.redirect) {
            return this.$router.push({ name: this.$route.query.redirect });
          }

          this.$router.push("/app/sellers");
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating seller,check your network or contact your developer`,
          });
        }
      },
      async UpdateSeller() {
        this.loadingSpinner = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!, $seller: SellerInput!) {
                updateSeller(id: $id, seller: $seller) {
                  _id
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
              seller: {
                name: this.seller.name,
                phoneNumber: this.seller.phoneNumber,
                ...(this.seller.email && {
                  email: this.seller.email,
                }),
                ...(this.seller.address && {
                  address: this.seller.address,
                }),
              },
            },
          });

          this.loadingSpinner = false;
          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }
          this.$emit("show-alert", {
            type: "success",
            message: "Seller updated successfully",
          });
          this.$router.push("/app/sellers");
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating seller,check your network or contact your developer`,
          });
        }
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Seller", pathName: "sellers" },
        ...(this.$route.params.mode == "edit"
          ? [
              {
                displayName: "Single Seller",
                pathName: "singleSeller",
                params: { id: this.$route.params.id },
              },
            ]
          : []),
        {
          displayName: "Manage Seller",
          pathName: "manageSeller",
          params: { id: this.$route.params.id, mode: this.$route.params.mode },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
