var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md:max-w-3/4 ml-auto mr-auto p-6 bg-white rounded-lg mb-4"},[_c('h1',{staticClass:"font-bold text-lg text-center text-darkblue mb-3"},[_vm._v(" "+_vm._s(_vm.$route.params.mode == "new" ? "New" : "Manage")+" Seller ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.SubmitSeller)}}},[_c('div',{staticClass:" mb-3 md:mb-6 justify-around flex flex-col md:flex-row"},[_c('div',{staticClass:"w-full md:w-5/12 mb-2"},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Seller's name"}},[_vm._v(" Name ")]),_c('validation-provider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.seller.name),expression:"seller.name"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"text","name":"Seller's name"},domProps:{"value":(_vm.seller.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.seller, "name", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full md:w-5/12 mb-2"},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Seller's email"}},[_vm._v(" Email ")]),_c('validation-provider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.seller.email),expression:"seller.email"}],staticClass:"border-2 md:text-xs w-full border-darkblue rounded-lg block",attrs:{"type":"email","name":"Seller's email"},domProps:{"value":(_vm.seller.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.seller, "email", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:" mb-3 md:mb-6 justify-around flex flex-col md:flex-row"},[_c('div',{staticClass:"w-full md:w-5/12 mb-2"},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Phone number"}},[_vm._v(" Phone Number ")]),_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.seller.phoneNumber),expression:"seller.phoneNumber"}],staticClass:"border-2 md:text-xs w-full border-darkblue rounded-lg block",attrs:{"name":"Phone number","type":"text"},domProps:{"value":(_vm.seller.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.seller, "phoneNumber", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full md:w-5/12 mb-2"},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Seller's address"}},[_vm._v(" Address ")]),_c('validation-provider',{attrs:{"rules":"custom_text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.seller.address),expression:"seller.address"}],staticClass:"border-2 md:text-xs w-full border-darkblue rounded-lg block",attrs:{"type":"text","name":"Seller's address"},domProps:{"value":(_vm.seller.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.seller, "address", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('button',{staticClass:"text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full",attrs:{"type":"submit"}},[(_vm.loadingSpinner)?_c('svg',{staticClass:"inline animate-spin mr-2",attrs:{"width":"25","height":"25","viewBox":"0 0 50 50","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M48 25C48 12.2975 37.7025 2 25 2","stroke":"black","stroke-width":"4"}}),_c('path',{attrs:{"d":"M48 25C48 37.7025 37.7025 48 25 48","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M2 25C2 12.2975 12.2975 2 25 2","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M25 48C12.2975 48 2 37.7025 2 25","stroke":"#C9000C","stroke-width":"4"}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.mode == "new" ? "Add Seller" : "Save Seller")+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }